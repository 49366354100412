import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";


const Root = lazy(() => import("./Root.js"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<React.StrictMode>{<Root />}</React.StrictMode>);

